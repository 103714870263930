<script>
import { wpService } from "@/services/wp";
export default {
  data() {
    return {
      nicename: "",
      nicenameValidate: false,
      email: "",
      emailValidate: false,
      submitted: false,
      loading: false,
      message: null,
    };
  },

  computed: {
    nicenameClass() {
      return (this.submitted || this.nicenameValidate) && this.nicenameInvalid
        ? "is-danger"
        : "";
    },
    emailClass() {
      return (this.submitted || this.emailValidate) && this.emailInvalid
        ? "is-danger"
        : "";
    },
    nicenameInvalid() {
      if (this.nicename.length < 2) {
        return "Introduzca un nombre.";
      }
      return false;
    },
    emailInvalid() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(this.email)) {
        return this.email.length
          ? "Introduzca un correo electrónico válido."
          : "Introduce tu correo electrónico.";
      }
      return false;
    },
  },

  methods: {
    handleSubmit() {
      this.submitted = true;
      if (this.nicenameInvalid || this.emailInvalid) return;
      this.message = null;
      this.loading = true;
      let body = {
        nicename: this.nicename,
        email: this.email,
      };
      wpService.account
        .signupRequest(body)
        .then(() => {
          this.$parent.close();
          this.$buefy.dialog.alert({
            title: "Registro exitoso!",
            message:
              "En unos momentos recibirá un correo electrónico con un enlace para confirmar y activar su cuenta.",
            confirmText: "OK!",
          });
        })
        .catch(error => {
          console.log(error);
          this.message = error.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div id="authRegister">
    <section>
      <h2 class="title">
        Acceda a características exclusivas con una cuenta gratuita
      </h2>
      <div class="content">
        <ul>
          <li>Panel de administración de publicaciones</li>
          <!-- <li>Herramientas de promoción de publicaciones</li> -->
          <!-- <li>Visualización de métricas de publicaciones</li> -->
          <li>Guarde sus sitios favoritos</li>
          <li>Perfil personalizado</li>
          <!-- <li>Controle fácilmente sus publicaciones</li> -->
        </ul>
        <p>Y mucho más.</p>
      </div>
    </section>
    <section>
      <h2 class="title">Registrarse</h2>
      <div class="media validation has-text-danger" v-if="message">
        <div class="media-left">
          <b-icon icon="exclamation-circle" size="is-medium" />
        </div>
        <div class="media-content">
          {{ message }}
        </div>
      </div>
      <form method="post" @submit.prevent="handleSubmit">
        <div class="field">
          <b-input
            :custom-class="nicenameClass"
            size="is-medium"
            id="nicename"
            name="name"
            rounded
            expanded
            placeholder="Nombre público"
            v-model="nicename"
            v-on:blur="nicenameValidate = true"
            v-on:input="nicenameValidate = false"
            :disabled="loading"
          />
          <div
            v-show="(submitted || nicenameValidate) && !!nicenameInvalid"
            class="help is-danger"
          >
            {{ nicenameInvalid }}
          </div>
        </div>
        <div class="field">
          <b-input
            :custom-class="emailClass"
            type="email"
            size="is-medium"
            id="login"
            name="email"
            rounded
            expanded
            placeholder="Correo electrónico"
            v-model="email"
            v-on:blur="emailValidate = true"
            v-on:input="emailValidate = false"
            :disabled="loading"
          />
          <div
            v-show="(submitted || emailValidate) && !!emailInvalid"
            class="help is-danger"
          >
            {{ emailInvalid }}
          </div>
        </div>
        <div class="content">
          <b-button
            nativeType="submit"
            type="is-primary"
            size="is-medium"
            rounded
            expanded
            :class="{ 'is-loading': loading }"
            :disabled="loading"
          >
            Continuar
          </b-button>
        </div>
        <div class="content">
          <p>
            Al crear una cuenta, acepto la Política de privacidad y los Términos
            de uso de Sitio.
          </p>
        </div>
      </form>
      <hr />
      <div class="content">
        <p>
          ¿Ya tiene una cuenta?
          <a href="" @click.prevent="$parent.isAction = 'login'"
            >Iniciar sesión</a
          >
        </p>
      </div>
    </section>
  </div>
</template>
